<template>
  <div class="application-components-scene-wrapper">
    <application-public
      prop="applicationHomeSchool"
      identification="应用案例-学校"
      :list="list"
    />
  </div>
</template>

<script>
import ApplicationPublic from './public.vue'
export default {
  name: 'ApplicationComponentsSchool',
  components: {
    ApplicationPublic
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>